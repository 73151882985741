import React from 'react';
import { graphql } from 'gatsby';
import tw from 'twin.macro';
import ReactMarkdown from 'react-markdown';
import Pill from '../../components/UI/Pill';
import { codeComponent } from '../../utils/markdownComponents';
import Layout from '../../components/UI/Layout';
import Card from '../../components/UI/Card';

const SampleQuery = ({ data }) => {
  const { Title, Content, Description, Tags, published_at } =
    data.strapiSampleQueries;

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d?.getMonth() + 1}/${d?.getDate()}/${d?.getFullYear()}`;
  };

  return (
    <Layout>
      <div
        css={[tw`px-4 flex-col mt-8 max-w-5xl md:px-16 mx-auto items-center`]}
      >
        <div css={[tw`flex flex-col space-y-8`]}>
          <Card styles={[tw`px-4 md:px-12`]}>
            <div>
              <div className="markdown-body" css={[Content && tw`mb-4`]}>
                <h1>{Title}</h1>
                <p>{Description}</p>
              </div>
              <ReactMarkdown
                className="markdown-body"
                components={codeComponent}
              >
                {Content}
              </ReactMarkdown>
              <div css={[tw`flex space-x-2 w-full mt-8`]}>
                {Tags.split(',').map((tag, index) => (
                  <Pill key={index} title={tag} />
                ))}
              </div>
              <p css={[tw`text-gray-400 text-sm text-right`]}>
                Published on {formatDate(published_at)}
              </p>
            </div>
          </Card>
        </div>
      </div>
    </Layout>
  );
};

export default SampleQuery;

export const query = graphql`
  query getSingleSampleQuery($id: String) {
    strapiSampleQueries(id: { eq: $id }) {
      Title
      Content
      Tags
      published_at
      Description
    }
  }
`;
